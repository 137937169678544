const faqs = [
  {
    question: "1. ¿Es obligatorio tener un seguro de viaje para ir a Brasil?",
    answer: (
      <div>
        <p>
          No es obligatorio tener un seguro de viaje para ingresar a Brasil,
          pero es altamente recomendable. Contar con un seguro te protege ante
          posibles gastos médicos y otros imprevistos, brindándote tranquilidad
          durante tu estancia.
        </p>
      </div>
    ),
  },
  {
    question: "2. ¿Cuál es el seguro de viaje más económico para Brasil?",
    answer: (
      <div>
        <p>
          El seguro de viaje más económico para Brasil dependerá de tus
          necesidades y preferencias. Es importante comparar diferentes opciones
          y coberturas para encontrar la que mejor se ajuste a tu presupuesto
          sin comprometer la protección.
        </p>
      </div>
    ),
  },
  {
    question: "3. ¿Qué cubre un seguro médico de viaje a Brasil?",
    answer: (
      <div>
        <p>
          Un seguro médico de viaje a Brasil generalmente cubre emergencias
          médicas, hospitalización, repatriación, y asistencia médica las 24
          horas. También puede incluir cobertura para medicamentos, tratamientos
          ambulatorios y consultas médicas.
        </p>
      </div>
    ),
  },
  {
    question: "4. ¿Cómo puedo cotizar un seguro de viaje a Brasil?",
    answer: (
      <div>
        <p>
          Para cotizar un seguro de viaje a Brasil, puedes utilizar herramientas
          en línea como las ofrecidas por ASSIST 365. Simplemente ingresa la
          información de tu viaje y recibirás diferentes opciones de seguros con
          sus respectivos precios y coberturas, permitiéndote elegir la mejor
          opción para ti.
        </p>
      </div>
    ),
  },
];

export default faqs;
